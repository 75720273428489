<template>
  <div class="detailcontent">
    <div style="font-size: 30px; text-align: center; margin: 10px 10px">
      {{ current.siteName }}
    </div>
    <div style="font-size: 20px; text-align: center; margin-bottom: 10px">
      工地地址:&nbsp;{{ current.siteAddress }}
    </div>
    <div style="margin-top: 20px;background-color: rgba(#02208c, 0.8);" v-for="(item, index) in dataList" :key="index">
      <div style="margin-left: 10px">施工部位:&nbsp;{{ item.constructionPart }}</div>
      <div class="card">
        <div class="card-panel">
          <div class="card-panel-text">任务方量</div>
          <!-- <count-to :start-val="0" :end-val="item.quantityNumber" :duration="2000" /> -->
          <div class="element" style="border-color: #9400d3">
            {{ item.quantityNumber }}
          </div>
        </div>
        <div class="card-panel">
          <div class="card-panel-text">已完成方量</div>
          <!-- <countTo :startVal='1' :endVal='item.currentQuantityNumber' :duration='2000'></countTo> -->
          <div class="element" style="border-color: #ff69b4">
            {{ item.currentQuantityNumber || 0 }}
          </div>
        </div>
        <div
          class="card-panel"
          v-for="(v, carIndex) in item.carNumberList"
          :key="carIndex"
        >
          <div class="card-panel-text">
            {{ v }}
          </div>
          <!-- <countTo :startVal='1' :endVal='item.carTotalQuantityList[v]' :duration='2000'></countTo> -->
          <div class="element" :class="'border-color' + [carIndex % 6]">
            {{ item.carTotalQuantityList[v] }}
          </div>
        </div>
      </div>
      <div style="margin-left: 10px">车辆小时方量列表</div>
      <div style="margin-left: 5px">
        <el-table
          :row-style="tableRowStyle"
          :header-row-style="tableHeaderColor"
          size="mini"
          :data="item.carDataList"
          border
          style="max-height: 400px"
        >
          <el-table-column
            prop="reportTime"
            label="时间"
            width="180"
            fixed="left"
          ></el-table-column>
          <el-table-column
            width="120"
            v-for="(item, index) in item.carNumberList"
            :key="index"
            :prop="item"
            :label="item"
          >
            <template slot-scope="scope">
              <span> {{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'siteuser-real',

  data () {
    return {
      current: {
        siteName: '',
        siteAddress: ''
      },
      dataList: [],
      timer: null
    }
  },
  created () {
    this.queryList()
  },
  destroyed () {
    clearTimeout(this.timer)
  },
  watch: {
    dataList: {
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 设置表格行的样式
    tableRowStyle ({ row, rowIndex }) {
      let obj = { 'background-color': '#02208c', opacity: '0.7', color: 'white' }
      return obj
    },
    // 设置表头行的样式
    tableHeaderColor ({ row, column, rowIndex, columnIndex }) {
      let obj = { 'background-color': '#02208c', opacity: '0.7', color: '#02208c' }
      return obj
    },
    queryList () {
      clearTimeout(this.timer)
      this.$http({
        url: this.$http.adornUrl('/siteuser/queryrealdata'),
        method: 'post',
        data: {}
      }).then((data) => {
        if (data && data.code === 200) {
          this.dataList = data.datas
          if (this.dataList && this.dataList.length > 0) {
            this.current.siteName = this.dataList[0].siteName
            this.current.siteAddress = this.dataList[0].siteAddress
            this.timer = setTimeout(this.queryList, 60 * 1000)
          }
        } else {
          console.log(data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
}
.card-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 5px;
  font-size: 20px;
  background-color: rgba(#02208c, 0.8);
  width: 200px;
  height: 200px;
}
.card-panel-text {
  margin-bottom: 10px;
}

.detailcontent {
  background-color: rgba(#02208c, 0.8);
  min-height: 937px;
  color: white;
}

.el-table,
.el-table-column {
  background-color: rgba(#02208c, 0.7);
}

.element {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-width: 20px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-color0 {
  border-color: #7b68ee;
}
.border-color1 {
  border-color: #00cc33;
}
.border-color2 {
  border-color: #ff00ff;
}
.border-color3 {
  border-color: #ffa07a;
}
.border-color4 {
  border-color: #32cd32;
}
.border-color5 {
  border-color: #ff0000;
}

.el-table,
.el-table--border,
.el-table--group {
  border: none !important;
}
</style>
